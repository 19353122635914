import React, { useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';

import DisplayError from '~/util/DisplayError';

import Input from '~/components/Input';
import FileInput from '~/components/FileInput';
import Container from '~/components/DefaultContainer';
import Button from '~/components/Button';
import TextError from '~/components/TextError';
import Form from '~/components/Form';

import {
  fileValidation,
  slugValidation,
  priorityValidation,
  bannerValidation,
} from '~/util/bannerValidation';

const MySwal = withReactContent(Swal);

export default function BannerCreate() {
  const history = useHistory();
  const [toUploadImage, setToUploadImage] = useState('');
  const [file, setFile] = useState();
  const [errors, setErrors] = useState({
    slug: '',
    priority: '',
    file: '',
  });
  const [formData, setFormData] = useState({
    slug: '',
    priority: 0,
  });

  const handleChange = (e) => {
    const errorsTemp = errors;
    if (e.target.name === 'slug') {
      errorsTemp.slug = slugValidation(e.target.value);
    } else if (e.target.name === 'priority') {
      errorsTemp.priority = priorityValidation(e.target.value);
    }
    setErrors(errorsTemp);

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFile = (e) => {
    const errorsTemp = errors;
    errorsTemp.file = fileValidation(file);
    setErrors(errorsTemp);

    const reader = new FileReader();
    reader.onload = (event) => {
      setToUploadImage(event.target.result);
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      setFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errorsTemp = bannerValidation(
      errors,
      formData.slug,
      formData.priority,
      file
    );
    setErrors(errorsTemp);
    if (errorsTemp.slug || errorsTemp.file || errors.priority) {
      return;
    }

    try {
      const formDataToSend = new FormData();

      formDataToSend.append('slug', formData.slug);
      formDataToSend.append('priority', formData.priority);
      formDataToSend.append('file', file);

      await api.post('/banner/create', formDataToSend, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      });

      MySwal.fire({
        icon: 'success',
        title: 'Banner criado',
        timer: 1500,
        showConfirmButton: false,
      });
      history.push('/banner');
    } catch (err) {
      if (err.response) {
        DisplayError('Não foi possível criar o banner');
      } else {
        DisplayError('Erro desconhecido');
      }
    }
  };

  return (
    <Container>
      <div>
        {toUploadImage && <img src={toUploadImage} alt="" />}
        <Form onSubmit={handleSubmit}>
          <FileInput label="Imagem" name="file" onChange={handleFile} />
          {errors.file && <TextError>{errors.file}</TextError>}
          <Input
            placeholder="www.certto.com.br"
            label="Slug(Link para redireção)"
            name="slug"
            onChange={handleChange}
          />
          {errors.slug && <TextError>{errors.slug}</TextError>}
          <Input
            label="Prioridade(para aparecer antes de outro banner)"
            placeholder="0"
            type="number"
            name="priority"
            onChange={handleChange}
            value={formData.priority}
          />
          {errors.priority && <TextError>{errors.priority}</TextError>}
          <Button>Enviar</Button>
        </Form>
      </div>
    </Container>
  );
}
