import styled from 'styled-components';

export const Container = styled.button`
  background-color: ${(props) => props.theme.MAIN_THEME_COLOR};
  width: 250px;
  height: 250px;
  margin: 10px;
  box-shadow: 4px 4px #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  &:hover {
    background-color: ${(props) => props.theme.MAIN_THEME_COLOR_DARK};
  }
  &:active {
    background-color: ${(props) => props.theme.MAIN_THEME_COLOR_DARKER};
  }
`;

export const Text = styled.span`
  font-size: 24px;
  color: white;
  font-weight: 600;
`;
