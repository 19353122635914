import axios from 'axios';

export const baseURL = 'https://appclientescertto.midiasimples.com.br';

// export const baseURL = 'http://192.168.0.15:3451';

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const api = axios.create({
  baseURL,
  timeout: 20000,
});

export default api;
