import React, { useEffect, useState } from 'react';
import DefaultContainer from '~/components/DefaultContainer';
import api from '~/services/api';

import Item from './Item';

export default function PlayhubAccess() {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    async function fetchLogs() {
      const { data } = await api.get('/playhub/logs');
      setLogs(data);
    }

    fetchLogs();
  }, []);

  return (
    <DefaultContainer>
      {logs?.map((log) => (
        <Item {...log} />
      ))}
    </DefaultContainer>
  );
}
