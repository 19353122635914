import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import DefaultLayout from '~/pages/_layouts/default';
import { useAuthStore } from '~/store/auth';

function RouteWrapper({ component: Component, authorized, ...rest }) {
  const signed = useAuthStore((store) => store.signed);

  if (!signed && authorized) return <Redirect to="/login" />;

  return (
    <DefaultLayout>
      <Route {...rest} render={(props) => <Component {...props} />} />
    </DefaultLayout>
  );
}

RouteWrapper.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  authorized: PropTypes.bool,
};

RouteWrapper.defaultProps = {
  authorized: false,
};

export default RouteWrapper;
