import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 10px;
`;

export const InputStyle = styled.input`
  padding: 10px;
  border: none;
  display: block;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;

  > img {
    height: 40px;
  }
`;
