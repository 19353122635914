import isValidURL from '~/util/isValidURL';

export function fileValidation(file) {
  if (!file) {
    return 'Campo obrigatório';
  }
  return '';
}

export function slugValidation(slug) {
  if (!slug) {
    return 'Campo obrigatório';
  }
  if (!isValidURL(slug)) {
    return 'Slug deve ser um URL';
  }
  return '';
}

export function priorityValidation(priority) {
  if (!priority) {
    return 'Campo obrigatório';
  }
  return '';
}

export function bannerValidation(errors, slug, priority, file) {
  const errorsTemp = errors;

  errorsTemp.slug = slugValidation(slug);
  errorsTemp.priority = priorityValidation(priority);
  errorsTemp.file = fileValidation(file);

  return errorsTemp;
}
