import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Form = styled.form`
  width: 400px;
  min-height: 300px;
`;

export const FormTitle = styled.h1`
  color: ${(props) => props.theme.MAIN_THEME_COLOR};
  font-weight: 600;
  font-size: 24px;
`;
