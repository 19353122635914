export default {
  MAIN_THEME_COLOR: '#0090D4',
  MAIN_THEME_COLOR_DARK: '#006ad4',
  MAIN_THEME_COLOR_DARKER: '#0050a1',
  INPUT_BAR: '#d9d9d9',
  ERROR: 'red',
  WARNING: '#ff2a00',
  WARNING_DARK: '#c90000',
  WARNING_DARKER: '#990012',
};
