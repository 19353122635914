import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import SignIn from '~/pages/SignIn';
import Dashboard from '~/pages/Dashboard';

import MassPush from '~/pages/MassPush';

import BannerCreate from '~/pages/BannerCreate';
import BannerUpdate from '~/pages/BannerUpdate';
import BannerDelete from '~/pages/BannerDelete';
import BannerList from '~/pages/BannerList';

import DatasVencimentoList from '~/pages/DatasVencimentoList';
import DatasVencimentoCreate from '~/pages/DatasVencimentoCreate';
import DatasVencimentoUpdate from '~/pages/DatasVencimentoUpdate';
import DatasVencimentoDelete from '~/pages/DatasVencimentoDelete';

import FAQTopicsCreate from '~/pages/FAQTopicsCreate';
import FAQTopicsUpdate from '~/pages/FAQTopicsUpdate';
import FAQTopicsDelete from '~/pages/FAQTopicsDelete';
import FAQTopicsList from '~/pages/FAQTopicsList';

import FAQItemsCreate from '~/pages/FAQItemsCreate';
import FAQItemsUpdate from '~/pages/FAQItemsUpdate';
import FAQItemsDelete from '~/pages/FAQItemsDelete';
import FAQItemsList from '~/pages/FAQItemsList';

import Account from '~/pages/Account';
import { useAuthStore } from '~/store/auth';
import PlayhubAccess from '~/pages/PlayhubAccess';
import PushV2 from '~/pages/PushV2';
import Route from './Route';

export default function Routes() {
  const signOut = useAuthStore((store) => store.signOut);

  return (
    <Switch>
      <Route exact path="/" component={() => <Redirect to="/login" />} />
      <Route path="/login" component={SignIn} />
      <Route
        path="/logoff"
        component={() => {
          signOut();
          return <Redirect to="/login" />;
        }}
      />
      <Route authorized exact path="/dashboard" component={Dashboard} />
      <Route authorized exact path="/banner" component={BannerList} />
      <Route authorized path="/banner/create" component={BannerCreate} />
      <Route authorized path="/banner/update/:id" component={BannerUpdate} />
      <Route authorized path="/banner/delete/:id" component={BannerDelete} />
      <Route authorized exact path="/data" component={DatasVencimentoList} />
      <Route authorized path="/data/create" component={DatasVencimentoCreate} />
      <Route
        authorized
        path="/data/update/:id"
        component={DatasVencimentoUpdate}
      />
      <Route
        authorized
        path="/data/delete/:id"
        component={DatasVencimentoDelete}
      />
      <Route authorized exact path="/faq/topics" component={FAQTopicsList} />
      <Route authorized path="/faq/topics/create" component={FAQTopicsCreate} />
      <Route
        authorized
        path="/faq/topics/update/:id"
        component={FAQTopicsUpdate}
      />
      <Route
        authorized
        path="/faq/topics/delete/:id"
        component={FAQTopicsDelete}
      />
      <Route authorized exact path="/faq/items/:id" component={FAQItemsList} />
      <Route
        authorized
        path="/faq/items/:id/create"
        component={FAQItemsCreate}
      />
      <Route
        authorized
        path="/faq/items/:id/update/:itemId"
        component={FAQItemsUpdate}
      />
      <Route
        authorized
        path="/faq/items/:id/delete/:itemId"
        component={FAQItemsDelete}
      />
      <Route authorized path="/push-v2" component={PushV2} />
      <Route authorized path="/mass-push" component={MassPush} />
      <Route authorized path="/account" component={Account} />
      <Route authorized path="/playhub-logs" component={PlayhubAccess} />
      <Route path="*" component={() => <h1>404</h1>} />
    </Switch>
  );
}
