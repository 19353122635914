import styled from 'styled-components';

export const Image = styled.img`
  width: 20px;
  height: 20px;
  margin: 10px;
  animation: rotate 2s infinite;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;
