export function titleValidation(title) {
  if (!title) {
    return 'Campo obrigatório';
  }
  return '';
}

export function contentValidation(content) {
  if (!content) {
    return 'Campo obrigatório';
  }
  if (content.length >= 2000) {
    return 'Máximo 2000 caracteres';
  }
  return '';
}

export function topicItemValidation(errors, title, content) {
  const errorsTemp = errors;
  errorsTemp.title = titleValidation(title);
  errorsTemp.content = contentValidation(content);
  return errorsTemp;
}
