import React from 'react';
import PropTypes from 'prop-types';

import { Container, TextAreaStyle } from './styles';

function TextArea({ label: labelString, name, ...rest }) {
  return (
    <Container>
      <label htmlFor={name}>{labelString}</label>
      <TextAreaStyle id={name} name={name} {...rest} />
    </Container>
  );
}

TextArea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

TextArea.defaultProps = {
  label: '',
};

export default TextArea;
