import React from 'react';
import PropTypes from 'prop-types';

import { Container, IconContainer, InputStyle } from './styles';

function Input({ icon: Icon, label: labelString, name, ...rest }) {
  return (
    <>
      <label htmlFor={name}>{labelString}</label>
      <Container>
        {Icon && (
          <IconContainer>
            <Icon />
          </IconContainer>
        )}
        <InputStyle id={name} name={name} {...rest} />
      </Container>
    </>
  );
}

Input.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

Input.defaultProps = {
  icon: null,
  label: '',
};

export default Input;
