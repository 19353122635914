import React from 'react';

import Container from '~/components/DefaultContainer';

import Card from '~/components/Card';

export default function Dashboard() {
  return (
    <Container>
      <Card redirectTo="/banner">Gerenciar banners</Card>
      <Card redirectTo="/data">Gerenciar datas de vencimento</Card>
      <Card redirectTo="/faq/topics">Gerenciar FAQ</Card>
      <Card redirectTo="/mass-push">Gerenciar notificações em massa</Card>
      <Card redirectTo="/push-v2">Gerenciar notificações com seguimentos</Card>
      <Card redirectTo="/playhub-logs">Playhub</Card>
    </Container>
  );
}
