import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom';

import api, { baseURL } from '~/services/api';

import DisplayError from '~/util/DisplayError';

import Input from '~/components/Input';
import FileInput from '~/components/FileInput';
import Container from '~/components/DefaultContainer';
import Button from '~/components/Button';
import TextError from '~/components/TextError';
import Form from '~/components/Form';

import { slugValidation, priorityValidation } from '~/util/bannerValidation';

const MySwal = withReactContent(Swal);

export default function BannerUpdate() {
  const history = useHistory();
  const [file, setFile] = useState();
  const [toUploadImage, setToUploadImage] = useState('');
  const [errors, setErrors] = useState({
    slug: '',
    file: '',
  });
  const [formData, setFormData] = useState({
    slug: '',
    priority: 0,
  });
  const { banner } = history.location.state || {};

  useEffect(() => {
    setFormData({
      slug: banner.slug,
      priority: banner.priority,
    });
  }, []);

  const handleChange = (e) => {
    const errorsTemp = errors;
    if (e.target.name === 'slug') {
      errorsTemp.slug = slugValidation(e.target.value);
    } else if (e.target.name === 'priority') {
      errorsTemp.priority = priorityValidation(e.target.value);
    }
    setErrors(errorsTemp);

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFile = (e) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      setToUploadImage(event.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (errors.slug || errors.priority) return;

    try {
      const formDataToSend = new FormData();

      formDataToSend.append('slug', formData.slug);
      formDataToSend.append('priority', formData.priority);
      formDataToSend.append('file', file);

      await api.put(`/banner/update/${banner.id}`, formDataToSend, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      });

      MySwal.fire({
        icon: 'success',
        title: 'Banner atualizado',
        timer: 1500,
        showConfirmButton: false,
      });
      history.push('/banner');
    } catch (err) {
      if (err.response) {
        DisplayError('Não foi possível atualizar o banner');
      } else {
        DisplayError('Erro desconhecido');
      }
    }
  };

  return (
    <Container>
      <div>
        <img
          src={toUploadImage || `${baseURL}/banner/${banner.filename}`}
          alt=""
        />
        <Form onSubmit={handleSubmit}>
          <FileInput label="Imagem" name="file" onChange={handleFile} />
          {errors && <TextError>{errors.file}</TextError>}
          <Input
            value={formData.slug}
            placeholder="www.certto.com.br"
            label="Slug(Link para redireção)"
            name="slug"
            onChange={handleChange}
          />
          {errors && <TextError>{errors.slug}</TextError>}
          <Input
            label="Prioridade(para aparecer antes de outro banner)"
            placeholder="0"
            value={formData.priority}
            type="number"
            name="priority"
            onChange={handleChange}
          />
          {errors.priority && <TextError>{errors.priority}</TextError>}
          <Button>Enviar</Button>
        </Form>
      </div>
    </Container>
  );
}
