import styled from 'styled-components';

import logo from '~/assets/logo.svg';
import hamburger from '~/assets/hamburger.svg';

const headerSize = 60;

export const Header = styled.header`
  height: ${headerSize}px;
  background-color: ${(props) => props.theme.MAIN_THEME_COLOR};
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Logo = styled.img.attrs({
  src: logo,
})`
  height: 40px;
  margin: 10px;
`;

export const Options = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const HamburgerImage = styled.img.attrs({
  src: hamburger,
})`
  height: 40px;
  width: 40px;
`;

export const HamburgerButton = styled.button`
  border: none;
  background-color: unset;
  margin: 10px;

  &:hover {
    cursor: pointer;
  }
`;
