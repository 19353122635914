import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import UDOptions from '~/components/UDOptions';
import { Container, SeeItemsButton } from './styles';

function Topico({ topic, topicId, editOnPress, deleteOnPress }) {
  const history = useHistory();

  return (
    <Container>
      <span>{topic}</span>
      <div>
        <UDOptions
          noBottomRadius
          editOnPress={editOnPress}
          deleteOnPress={deleteOnPress}
        />
        <SeeItemsButton onClick={() => history.push(`/faq/items/${topicId}`)}>
          Ver itens
        </SeeItemsButton>
      </div>
    </Container>
  );
}

Topico.propTypes = {
  topic: PropTypes.string.isRequired,
  topicId: PropTypes.number.isRequired,
  editOnPress: PropTypes.func.isRequired,
  deleteOnPress: PropTypes.func.isRequired,
};

export default Topico;
