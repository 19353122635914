import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import api, { baseURL } from '~/services/api';

import DisplayError from '~/util/DisplayError';

import Container from '~/components/DefaultContainer';
import Card from '~/components/Card';

import BannerCard from './BannerCard';

export default function BannerList() {
  const [banners, setBanners] = useState([]);
  const history = useHistory();
  const { redirectTo } = history.location.state || {};

  useEffect(() => {
    async function fetchBanners() {
      try {
        setBanners((await api.get('/banner')).data);
      } catch (err) {
        DisplayError('Não foi possível listar banners');
      }
    }
    fetchBanners();
  }, []);

  return (
    <Container>
      <Card redirectTo="/banner/create">Adicionar banner</Card>
      {banners &&
        banners.map((banner) => (
          <BannerCard
            key={banner.filename}
            onClick={() => {
              if (redirectTo) {
                history.push({
                  pathname: `${redirectTo}/${banner.id}`,
                  state: { banner },
                });
              }
            }}
            editOnPress={() =>
              history.push({
                pathname: `/banner/update/${banner.id}`,
                state: { banner },
              })
            }
            deleteOnPress={() =>
              history.push({
                pathname: `/banner/delete/${banner.id}`,
                state: { banner },
              })
            }
            id={banner.id}
            priority={banner.priority}
            source={`${baseURL}/banner/${banner.filename}`}
          />
        ))}
    </Container>
  );
}
