import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import api from '~/services/api';

import Container from '~/components/DefaultContainer';
import Card from '~/components/Card';

import DisplayError from '~/util/DisplayError';

import Item from './Item';

export default function FAQItemsList() {
  const [items, setItems] = useState([]);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    async function fetchItems() {
      try {
        setItems(
          (await api.get('/faq/item')).data
            .map((item) => {
              if (item.content.length > 25) {
                return {
                  ...item,
                  content: `${item.content.slice(0, 25)}...`,
                  fullContent: item.content,
                };
              }
              return {
                ...item,
                fullContent: item.content,
              };
            })
            .filter((item) => item.topic_id === Number(id))
        );
      } catch (err) {
        DisplayError('Não foi possível listar itens');
      }
    }
    fetchItems();
  }, []);

  return (
    <Container>
      <Card redirectTo={`/faq/items/${id}/create`}>Adicionar item</Card>
      {items &&
        items.map((item) => (
          <Item
            key={item.title}
            title={item.title}
            content={item.content}
            editOnPress={() =>
              history.push({
                pathname: `/faq/items/${id}/update/${item.id}`,
                state: { item },
              })
            }
            deleteOnPress={() =>
              history.push({
                pathname: `/faq/items/${id}/delete/${item.id}`,
                state: { item },
              })
            }
          />
        ))}
    </Container>
  );
}
