import React from 'react';
import { Controller } from 'react-hook-form';
import TextArea from '../TextArea';

function TextAreaController({ control, name, ...rest }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <TextArea {...field} {...rest} />}
    />
  );
}

export default TextAreaController;
