import React from 'react';
import PropTypes from 'prop-types';

import UDOptions from '~/components/UDOptions';
import { Container, Image, Values } from './styles';

function BannerCard({
  source,
  id,
  priority,
  editOnPress,
  deleteOnPress,
  ...rest
}) {
  return (
    <Container {...rest}>
      <Image src={source} alt="" />
      <Values>Id: {id}</Values>
      <Values>Prioridade: {priority}</Values>
      <UDOptions editOnPress={editOnPress} deleteOnPress={deleteOnPress} />
    </Container>
  );
}

BannerCard.propTypes = {
  source: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  priority: PropTypes.number.isRequired,
  editOnPress: PropTypes.func.isRequired,
  deleteOnPress: PropTypes.func.isRequired,
};

export default BannerCard;
