import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';

import Card from '~/components/Card';
import Container from '~/components/DefaultContainer';

import DisplayError from '~/util/DisplayError';

import Topico from './Topico';

export default function FAQTopicsList() {
  const [topics, setTopics] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function fetchTopics() {
      try {
        setTopics((await api.get('/faq')).data);
      } catch (err) {
        DisplayError('Não foi possível listar tópicos');
      }
    }
    fetchTopics();
  }, []);

  return (
    <Container>
      <Card redirectTo="/faq/topics/create">Adicionar tópico</Card>
      {topics &&
        topics.map((topic) => (
          <Topico
            key={topic.title}
            topic={topic.title}
            topicId={topic.id}
            editOnPress={() => {
              history.push({
                pathname: `/faq/topics/update/${topic.id}`,
                state: {
                  topic,
                },
              });
            }}
            deleteOnPress={() => {
              history.push({
                pathname: `/faq/topics/delete/${topic.id}`,
                state: {
                  topic,
                },
              });
            }}
          />
        ))}
    </Container>
  );
}
