import styled from 'styled-components';

export const Container = styled.button`
  background-color: ${(props) =>
    props.warning ? props.theme.WARNING : props.theme.MAIN_THEME_COLOR};
  border: none;
  width: 100%;
  height: 50px;
  margin: 10px 0;
  border-radius: 5px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;

  &:hover {
    background-color: ${(props) =>
      props.warning
        ? props.theme.WARNING_DARK
        : props.theme.MAIN_THEME_COLOR_DARK};
  }
  &:active {
    background-color: ${(props) =>
      props.warning
        ? props.theme.WARNING_DARKER
        : props.theme.MAIN_THEME_COLOR_DARK};
  }
`;
