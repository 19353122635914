import React from 'react';
import PropTypes from 'prop-types';

import { Container, EditButton, DeleteButton } from './styles';

function UDOptions({ editOnPress, deleteOnPress, noBottomRadius, ...rest }) {
  return (
    <Container {...rest}>
      <EditButton noBottomRadius={noBottomRadius} onClick={() => editOnPress()}>
        Editar
      </EditButton>
      <DeleteButton
        noBottomRadius={noBottomRadius}
        onClick={() => deleteOnPress()}
      >
        Deletar
      </DeleteButton>
    </Container>
  );
}

UDOptions.propTypes = {
  editOnPress: PropTypes.func.isRequired,
  deleteOnPress: PropTypes.func.isRequired,
  noBottomRadius: PropTypes.bool,
};

UDOptions.defaultProps = {
  noBottomRadius: false,
};

export default UDOptions;
