import styled from 'styled-components';

export const Container = styled.div`
  width: 250px;
  height: 250px;
  margin: 10px;
  padding: 10px;
  background-color: white;
  overflow: hidden;
  box-shadow: 4px 4px #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Image = styled.img`
  height: 100px;
`;

export const Values = styled.span`
  display: block;
  color: ${(props) => props.theme.MAIN_THEME_COLOR};
`;
