import React from 'react';

import { Controller } from 'react-hook-form';
import TextError from '../TextError';
import { Container, IconContainer, InputStyle } from './styles';

function InputController({
  icon: Icon,
  label: labelString,
  name,
  control,
  rules,
  error,
  ...rest
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <>
          <label htmlFor={field.name}>{labelString}</label>
          <Container>
            {Icon && (
              <IconContainer>
                <Icon />
              </IconContainer>
            )}
            <InputStyle {...field} id={field.name} {...rest} />
          </Container>
          <TextError>{error?.message}</TextError>
        </>
      )}
    />
  );
}

export default InputController;
