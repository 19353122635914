import React from 'react';
import PropTypes from 'prop-types';

import UDOptions from '~/components/UDOptions';
import { Container, Title, Content } from './styles';

function Topico({ title, content, editOnPress, deleteOnPress }) {
  return (
    <Container>
      <Title>{title}</Title>
      <Content>{content}</Content>
      <UDOptions editOnPress={editOnPress} deleteOnPress={deleteOnPress} />
    </Container>
  );
}

Topico.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  editOnPress: PropTypes.func.isRequired,
  deleteOnPress: PropTypes.func.isRequired,
};

export default Topico;
