import styled from 'styled-components';

export const Container = styled.div`
  width: 150px;
  height: 50px;
`;

const Button = styled.button`
  width: 50%;
  font-weight: 600;
  color: white;
  border: none;
  height: 50px;

  &:hover {
    cursor: pointer;
  }
`;

export const EditButton = styled(Button)`
  background-color: #f0a000;
  border-radius: 5px 0px 0px ${(props) => (props.noBottomRadius ? '0' : '5px')};
`;

export const DeleteButton = styled(Button)`
  background-color: ${(props) => props.theme.WARNING};
  border-radius: 0px 5px ${(props) => (props.noBottomRadius ? '0px' : '5px')}
    0px;
`;
