import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Container } from './styles';

function AnchorButton({ children, href, ...rest }) {
  return (
    <Container {...rest}>
      <Link to={href}>{children}</Link>
    </Container>
  );
}

AnchorButton.propTypes = {
  children: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default AnchorButton;
