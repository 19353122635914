import styled from 'styled-components';

export const Container = styled.div`
  width: 250px;
  height: 250px;
  margin: 10px;
  padding: 10px;
  background-color: white;
  font-weight: 600;
  font-size: 16px;
  box-shadow: 4px 4px #e8e8e8;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const SeeItemsButton = styled.button`
  border-radius: 0px 0px 5px 5px;
  background-color: ${(props) => props.theme.MAIN_THEME_COLOR};
  height: 50px;
  width: 100%;
  color: white;
  font-weight: 600;
  font-size: 16px;
  border: none;

  &:hover {
    cursor: pointer;
  }
`;
