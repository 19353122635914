import styled from 'styled-components';
import UDOptionsComponent from '~/components/UDOptions';

export const Container = styled.div`
  width: 250px;
  height: 250px;
  margin: 10px;
  padding: 10px;
  background-color: white;
  border: none;
  font-weight: 600;
  font-size: 20px;
  box-shadow: 4px 4px #e8e8e8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const UDOptions = styled(UDOptionsComponent)`
  margin-top: 20px;
`;
