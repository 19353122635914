import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuthStore } from '~/store/auth';
import {
  Header,
  Logo,
  Options,
  HamburgerImage,
  HamburgerButton,
} from './styles';

import AnchorButton from '../AnchorButton';

export default function HeaderMobile() {
  const [dropdownSelected, setDropdownSelected] = useState(false);
  const signed = useAuthStore((store) => store.signed);

  const AnchorButtonMobile = ({ ...rest }) => (
    <AnchorButton style={{ height: 60 }} {...rest} />
  );

  return (
    <>
      <Header>
        <Link to={signed ? '/dashboard' : '/login'}>
          <Logo />
        </Link>
        <HamburgerButton onClick={() => setDropdownSelected(!dropdownSelected)}>
          <HamburgerImage />
        </HamburgerButton>
      </Header>
      <Options>
        {dropdownSelected && signed && (
          <>
            <AnchorButtonMobile href="/faq/topics">FAQ</AnchorButtonMobile>
            <AnchorButtonMobile href="/banner">Banners</AnchorButtonMobile>
            <AnchorButtonMobile href="/data">
              Datas de vencimento
            </AnchorButtonMobile>
            <AnchorButtonMobile href="/account">Conta</AnchorButtonMobile>
            <AnchorButtonMobile href="/logoff">Sair</AnchorButtonMobile>
          </>
        )}
        {dropdownSelected && !signed && (
          <AnchorButtonMobile href="/login">Login</AnchorButtonMobile>
        )}
      </Options>
    </>
  );
}
