import React from 'react';

import { Controller } from 'react-hook-form';
import { Container, IconContainer, InputStyle } from './styles';

function Checkbox({ icon: Icon, label: labelString, name, control, ...rest }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Container>
          <InputStyle type="checkbox" {...field} id={field.name} {...rest} />
          <label htmlFor={field.name}>{labelString}</label>
          {Icon && (
            <IconContainer>
              <Icon />
            </IconContainer>
          )}
        </Container>
      )}
    />
  );
}

export default Checkbox;
