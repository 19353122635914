import { format } from 'date-fns';
import React from 'react';

import { Container } from './styles';

function Item({ codcli, createdAt, type }) {
  const createdAtDate = new Date(createdAt);
  const formattedCreatedAt = format(createdAtDate, 'dd/MM/yyyy hh:mm:ss');

  return (
    <Container>
      <span>Codcli: {codcli}</span>
      <span>{formattedCreatedAt}</span>
      <span>
        {type === 'ACTIVITY' ? 'Acesso' : null}
        {type === 'DESTROY' ? 'Remoção dos serviços' : null}
      </span>
    </Container>
  );
}

export default Item;
