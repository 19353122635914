import styled from 'styled-components';

export const Form = styled.form`
  width: 250px;

  & > * {
    margin: 10px 0;
    height: 50px;
  }
`;

export const WarningText = styled.h2`
  color: ${(props) => props.theme.WARNING};
`;
