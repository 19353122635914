import React, { useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';

import DisplayError from '~/util/DisplayError';

import Input from '~/components/Input';
import Container from '~/components/DefaultContainer';
import Button from '~/components/Button';
import TextError from '~/components/TextError';
import { useAuthStore } from '~/store/auth';
import { Form } from './styles';

const MySwal = withReactContent(Swal);

export default function Account() {
  const signOut = useAuthStore((store) => store.signOut);
  const history = useHistory();
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    password: '',
  });

  const handleChange = (e) => {
    setError('');

    if (!e.target.value) setError('Campo obrigatório');
    else if (e.target.value.length < 6 || e.target.value.length > 50)
      setError('Senha entre 6 até 50 caracteres');

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.password) {
      setError('Campo obrigatório');
      return;
    }
    if (formData.password.length < 6 || formData.password.length > 50) {
      setError('Senha entre 6 até 50 caracteres');
      return;
    }

    try {
      await api.put('/admin/update', {
        passwordNew: formData.password,
      });

      signOut();

      MySwal.fire({
        icon: 'success',
        title: 'Dados alterados. Faça login novamente',
        timer: 1500,
        showConfirmButton: false,
      });
      history.push('/login');
    } catch (err) {
      if (err.response) {
        DisplayError('Não foi possível trocar seus dados');
      } else {
        DisplayError('Erro desconhecido');
      }
    }
  };

  return (
    <Container>
      <div>
        <Form onSubmit={handleSubmit}>
          <Input
            type="password"
            label="Nova senha"
            name="password"
            onChange={handleChange}
            value={formData.password}
          />
          {error && <TextError>{error}</TextError>}
          <Button>Enviar</Button>
        </Form>
      </div>
    </Container>
  );
}
