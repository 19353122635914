import React from 'react';
import PropTypes from 'prop-types';

function FileInput({ name, label: labelString, ...rest }) {
  return (
    <>
      <label id={name} htmlFor={name}>
        {labelString}
      </label>
      <input id={name} name={name} type="file" {...rest} />
    </>
  );
}

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FileInput;
