import { create } from 'zustand';
import api from '~/services/api';

export const useAuthStore = create((set) => ({
  token: '',
  signed: false,
  signIn: (token) => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    return set({ token, signed: true });
  },
  signOut: () => set({ token: '', signed: false }),
}));
