import styled from 'styled-components';

export const Container = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
`;

export const TextAreaStyle = styled.textarea`
  resize: none;
  border-radius: 5px;
  border: solid ${(props) => props.theme.INPUT_BAR} 2px;
  padding: 10px;
  display: block;
  width: 100%;
  flex-grow: 1;
  height: 200px;
`;
