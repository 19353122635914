import React from 'react';
import PropTypes from 'prop-types';

import Loading from '~/components/Loading';
import { Container } from './styles';

function ButtonComponent({ children, loading, warning, ...rest }) {
  return (
    <Container warning={warning} {...rest}>
      {loading && <Loading />}
      {children}
    </Container>
  );
}

ButtonComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  warning: PropTypes.bool,
  loading: PropTypes.bool,
};

ButtonComponent.defaultProps = {
  warning: false,
  loading: false,
};

export default ButtonComponent;
