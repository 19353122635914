import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useParams, useHistory } from 'react-router-dom';

import api from '~/services/api';

import DisplayError from '~/util/DisplayError';

import Input from '~/components/Input';
import TextArea from '~/components/TextArea';
import Container from '~/components/DefaultContainer';
import Button from '~/components/Button';
import TextError from '~/components/TextError';
import Form from '~/components/Form';

import {
  titleValidation,
  contentValidation,
  topicItemValidation,
} from '~/util/topicItemValidation';

const MySwal = withReactContent(Swal);

export default function FAQItemsUpdate() {
  const history = useHistory();
  const { id, itemId } = useParams();
  const { item } = history.location.state || {};
  const [errors, setErrors] = useState({
    title: '',
    content: '',
  });
  const [formData, setFormData] = useState({
    title: '',
    content: '',
  });

  const handleChange = (e) => {
    const errorsTemp = errors;
    if (e.target.name === 'title') {
      errorsTemp.title = titleValidation(e.target.value);
    }
    if (e.target.name === 'content') {
      errorsTemp.content = contentValidation(e.target.value);
    }
    setErrors(errorsTemp);

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    const errorsTemp = topicItemValidation(
      errors,
      formData.title,
      formData.content
    );
    setErrors(errorsTemp);
    e.preventDefault();

    if (errorsTemp.title || errorsTemp.content) return;

    try {
      await api.put(`/faq/item/${itemId}`, {
        title: formData.title,
        content: formData.content,
      });

      MySwal.fire({
        icon: 'success',
        title: 'Item de tópico atualizado',
        timer: 1500,
        showConfirmButton: false,
      });
      history.push(`/faq/items/${id}`);
    } catch (err) {
      if (err.response) {
        DisplayError('Não foi possível atualizar o item do tópico');
      } else {
        DisplayError('Erro desconhecido');
      }
    }
  };

  useEffect(() => {
    setFormData({
      title: item.title,
      content: item.fullContent,
    });
  }, []);

  return (
    <Container>
      <div>
        <Form onSubmit={handleSubmit}>
          <Input
            label="Título do item"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
          {errors.title && <TextError>{errors.title}</TextError>}
          <TextArea
            label="Descrição do item"
            name="content"
            value={formData.content}
            onChange={handleChange}
          />
          {errors.content && <TextError>{errors.content}</TextError>}
          <Button>Enviar</Button>
        </Form>
      </div>
    </Container>
  );
}
