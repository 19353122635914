import styled from 'styled-components';

const headerSize = 60;

export const Footer = styled.footer`
  height: ${headerSize}px;
  background-color: ${(props) => props.theme.MAIN_THEME_COLOR};
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div`
  padding-top: ${(props) => (props.mobile ? '0' : headerSize)}px;
`;
