import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';

import DisplayError from '~/util/DisplayError';

import Input from '~/components/Input';
import Container from '~/components/DefaultContainer';
import Button from '~/components/Button';
import TextError from '~/components/TextError';
import Form from '~/components/Form';

const MySwal = withReactContent(Swal);

export default function DatasVencimentoUpdate() {
  const history = useHistory();
  const [errors, setErrors] = useState({
    dia: '',
  });
  const [formData, setFormData] = useState({
    dia: '',
  });
  const { dia } = history.location.state || {};

  const handleValidation = (e) => {
    const errorsTemp = errors;
    if (Number(e.target.value) < 1 || Number(e.target.value) > 31) {
      errorsTemp.dia = 'O dia deve ser entre 1 e 31';
    } else {
      errorsTemp.dia = '';
    }
    return errorsTemp;
  };

  const handleChange = (e) => {
    setErrors(handleValidation(e));
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    const errorsTemp = handleValidation(e);
    setErrors(errorsTemp);
    e.preventDefault();

    if (errors.dia) return;

    try {
      await api.put(`/data/${dia.id}`, {
        dia: formData.dia,
      });

      MySwal.fire({
        icon: 'success',
        title: 'Dia de vencimento atualizado',
        timer: 1500,
        showConfirmButton: false,
      });
      history.push({
        pathname: '/data',
        state: {
          redirectTo: '/data/update',
        },
      });
    } catch (err) {
      if (err.response) {
        DisplayError('Não foi possível atualizar a data');
      } else {
        DisplayError('Erro desconhecido');
      }
    }
  };

  useEffect(() => {
    setFormData({
      dia: dia.dia,
    });
  }, []);

  return (
    <Container>
      <div>
        <Form onSubmit={handleSubmit}>
          <Input
            min="1"
            max="31"
            type="number"
            label="Dia"
            name="dia"
            onChange={handleChange}
            value={formData.dia}
          />
          {errors && <TextError>{errors.dia}</TextError>}
          <Button>Enviar</Button>
        </Form>
      </div>
    </Container>
  );
}
