import React, { useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';

import DisplayError from '~/util/DisplayError';

import Container from '~/components/DefaultContainer';
import Button from '~/components/Button';
import TextError from '~/components/TextError';
import Form from '~/components/Form';
import { useForm } from 'react-hook-form';

import TextAreaController from '~/components/TextAreaController';
import FileInputController from '~/components/FileInputController';
import InputController from '~/components/InputController';

const MySwal = withReactContent(Swal);

export default function MassPush() {
  const history = useHistory();
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();

  const onSubmit = async ({ file, url, message }) => {
    try {
      const formDataToSend = new FormData();

      formDataToSend.append('message', message);
      formDataToSend.append('url', url);
      formDataToSend.append('file', file[0]);

      await api.post('/onesignal/codcli/bulk', formDataToSend, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      });

      MySwal.fire({
        icon: 'success',
        title: 'Em breve as mensagens serão enviadas',
        timer: 1500,
        showConfirmButton: false,
      });
      history.push('/dashboard');
    } catch (err) {
      if (err.response) {
        DisplayError('Não foi possível enviar as notificações');
      } else {
        DisplayError('Erro desconhecido');
      }
    }
  };

  return (
    <Container>
      <div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="file">
            Planilha(CSV):
            <input accept=".csv" type="file" id="file" {...register('file')} />
          </label>
          {errors.file && <TextError>{errors.file}</TextError>}
          <TextAreaController
            control={control}
            label="Mensagem"
            placeholder="Mensagem"
            name="message"
          />
          {errors.message && <TextError>{errors.message}</TextError>}
          <InputController
            control={control}
            label="Link"
            placeholder="https://teste.com.br"
            name="url"
          />
          {errors.message && <TextError>{errors.message}</TextError>}
          <Button>Enviar</Button>
        </Form>
      </div>
    </Container>
  );
}
