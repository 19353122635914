import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Container from '~/components/DefaultContainer';
import Card from '~/components/Card';

import api from '~/services/api';

import DisplayError from '~/util/DisplayError';

import Data from './Data';

export default function DatasVencimentoList() {
  const [vencimentos, setVencimentos] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function fetchVencimentos() {
      try {
        setVencimentos(
          (await api.get('/data')).data.sort((a, b) => a.dia - b.dia)
        );
      } catch (err) {
        DisplayError('Não foi possível listar vencimentos');
      }
    }
    fetchVencimentos();
  }, []);

  return (
    <Container>
      <Card redirectTo="/data/create">Adicionar data de vencimento</Card>
      {vencimentos &&
        vencimentos.map((vencimento) => (
          <Data
            editOnPress={() =>
              history.push({
                pathname: `/data/update/${vencimento.id}`,
                state: { dia: vencimento },
              })
            }
            deleteOnPress={() =>
              history.push({
                pathname: `/data/delete/${vencimento.id}`,
                state: { dia: vencimento },
              })
            }
            key={vencimento.dia}
            dia={vencimento.dia}
          />
        ))}
    </Container>
  );
}
