import React from 'react';
import { QueryClientProvider } from 'react-query';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';

import history from '~/services/history';

import theme from '~/theme';
import queryClient from './lib/query';

import Routes from './routes';
import Global from './theme/global';

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <ToastContainer />
          <Global />
          <Routes />
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
