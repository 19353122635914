import styled from 'styled-components';

import logo from '~/assets/logo.svg';

const headerSize = 60;

export const Header = styled.header`
  height: ${headerSize}px;
  background-color: ${(props) => props.theme.MAIN_THEME_COLOR};
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
`;

export const Logo = styled.img.attrs({
  src: logo,
})`
  height: 40px;
  margin: 10px;
`;

export const Options = styled.div`
  height: 100%;
  display: flex;
`;
