import React from 'react';
import PropTypes from 'prop-types';

import { Container, UDOptions } from './styles';

function Data({ dia, editOnPress, deleteOnPress }) {
  return (
    <Container>
      <span>Dia {dia}</span>
      <UDOptions editOnPress={editOnPress} deleteOnPress={deleteOnPress} />
    </Container>
  );
}

Data.propTypes = {
  dia: PropTypes.number.isRequired,
  editOnPress: PropTypes.func.isRequired,
  deleteOnPress: PropTypes.func.isRequired,
};

export default Data;
