import React, { useState } from 'react';
import withReactContent from 'sweetalert2-react-content';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import Swal from 'sweetalert2';
import DefaultContainer from '~/components/DefaultContainer';
import InputController from '~/components/InputController';

import DisplayError from '~/util/DisplayError';
import Form from '~/components/Form';
import useCities from '~/hooks/useCities';
import Checkbox from '~/components/Checkbox';
import ButtonComponent from '~/components/Button';
import api from '~/services/api';
import history from '~/services/history';
import useCompanyPlans from '~/hooks/useCompanyPlans';

const MySwal = withReactContent(Swal);

export default function PushV2() {
  const onSubmit = async ({
    title,
    content,
    codclis,
    data,
    url,
    cities,
    minValue,
    codsers,
  }) => {
    const listOfCities = Object.entries(cities)
      .filter(([, value]) => value)
      .map(([key]) => key);

    const listOfPlans = Object.entries(codsers)
      .filter(([, value]) => value)
      .map(([key]) => key);

    const dataObject = {};

    data.forEach((entry) => {
      dataObject[entry.key] = entry.value;
    });

    try {
      await api.post('/onesignal/notifications', {
        title,
        content,
        codclis,
        cities: listOfCities,
        codsers: listOfPlans,
        data: dataObject,
        minValue,
        url,
      });
      history.push('Dashboard');

      MySwal.fire({
        icon: 'success',
        title: 'Notificação agendada',
        timer: 1500,
        showConfirmButton: false,
      });
    } catch (err) {
      DisplayError('Não foi possível criar a notificação');
    }
  };

  const pushMutation = useMutation({
    mutationFn: onSubmit,
    mutationKey: 'push-notification-v2',
  });
  const [numOfCodclis, setNumOfCodclis] = useState(0);
  const [numOfDatas, setNumOfDatas] = useState(0);
  const { data } = useCities();
  const { data: plansData } = useCompanyPlans();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      title: '',
      content: '',
      codclis: [],
      data: [],
      url: '',
      cities: {},
    },
  });

  const numRangeCodclis = [...Array(numOfCodclis).keys()];
  const numRangeData = [...Array(numOfDatas).keys()];

  return (
    <DefaultContainer>
      <Form>
        <InputController
          name="title"
          label="Título"
          control={control}
          rules={{
            required: 'O título é necessário',
          }}
          error={errors?.title}
        />
        <InputController
          name="content"
          label="Conteúdo"
          control={control}
          rules={{
            required: 'O conteúdo é necessário',
          }}
          error={errors?.content}
        />
        <InputController name="url" label="Link" control={control} />
        <p>Codclis</p>
        {numRangeCodclis.map((n) => (
          <InputController
            placeholder="000000"
            name={`codclis.${n}`}
            control={control}
            rules={{
              required: 'O codcli é necessário',
            }}
            error={errors?.codclis?.[n]}
          />
        ))}
        <button onClick={() => setNumOfCodclis((n) => n + 1)} type="button">
          Adicionar campo de codcli
        </button>
        <p>Dados</p>
        {numRangeData.map((n) => (
          <>
            <InputController
              placeholder="Chave"
              name={`data.${n}.key`}
              control={control}
              rules={{
                required: 'A chave é necessária',
              }}
              error={errors?.data?.[n]?.key}
            />
            <InputController
              placeholder="Valor"
              name={`data.${n}.value`}
              control={control}
              rules={{
                required: 'O valor é necessário',
              }}
              error={errors?.data?.[n]?.value}
            />
          </>
        ))}
        <button onClick={() => setNumOfDatas((n) => n + 1)} type="button">
          Adicionar chave/valor
        </button>
        <p>Cidades</p>
        {data?.map((city) => (
          <Checkbox control={control} name={`cities.${city}`} label={city} />
        ))}
        <InputController
          name="minValue"
          label="Valor mínimo de plano"
          type="number"
          step="0.01"
          control={control}
        />
        <p>Planos</p>
        {plansData?.map((plan) => (
          <Checkbox
            control={control}
            name={`codsers.${plan.codser}`}
            label={plan.name}
          />
        ))}
        <ButtonComponent onClick={handleSubmit(pushMutation.mutateAsync)}>
          Enviar
        </ButtonComponent>
      </Form>
    </DefaultContainer>
  );
}
